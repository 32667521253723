import {BI_ORIGINS} from '@wix/wix-events-commons-statics'
import {focusElement, hookToAttributeSelector} from '@wix/panda-js-utils'
import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import c from '../classnames.scss'
import {AboutSection} from '../event-details/about-section'
import {getAlignmentClass} from '../event-details/alignment'
import {FullDate} from '../event-details/full-date'
import {FullLocation} from '../event-details/full-location'
import {Map} from '../event-details/map'
import {RecurringDatesButton} from '../event-details/recurring-dates-button'
import {RecurringDatesCount} from '../event-details/recurring-dates-count'
import {SecondaryRsvpButton} from '../event-details/secondary-rsvp-button'
import {EventDetailsMobileNew} from '../event-details-mobile-new'
import {Members} from '../members'
import {SocialShareWithTitle} from '../social-share/with-title'
import {Subtitle} from '../subtitle'
import {RegistrationButton} from '../event-details/registration-button'
import {Schedule} from '../event-details/schedule'
import {GroupActivity} from '../event-details/group-activity'
import {MobileTicketsPicker} from '../checkout/mobile-checkout/mobile-tickets-picker'
import {useSettings} from '../../../hooks/settings'
import {settingsParams} from '../../../settingsParams'
import s from './event-details-mobile.scss'
import {MobileImage} from './mobile-image'
import {Description} from './description'
import {EventDetailsProps, ContainerProps} from './index'

export const EventDetailsMobile = ({
  componentSettings,
  eventTitle,
  headerAlignment,
  contentAlignment,
  membersVisible,
  t,
  mapVisible,
  aboutSectionVisible,
  socialShareVisible,
  scheduleVisible,
  sideBySideHeaderLayout,
  descriptionVisible,
  detailsPageLoaded,
  handleRSVP,
  mainImage,
  eventDescription,
  moreDatesVisible,
  moreDatesButtonVisible,
  groupVisible,
  hasTickets,
  ticketedEvent,
  withSeatingPlan,
  openSeatingPlan,
  updatedInterfaceEnabled,
}: EventDetailsProps) => {
  React.useEffect(() => {
    detailsPageLoaded()
    focusElement({selector: hookToAttributeSelector(DH.eventDetails)})
  }, [])

  const isImageVisible = () => Boolean(mainImage)

  const handleRegistrationButtonClick = () => {
    if (ticketedEvent) {
      if (withSeatingPlan) {
        return openSeatingPlan()
      }
    } else {
      return handleRSVP()
    }
  }

  const settings = useSettings(componentSettings)
  const timeAndLocationTitleText = settings.get(settingsParams.timeAndLocationTitleText)
  const aboutTitleText = settings.get(settingsParams.aboutTitleText)
  const readMoreText = settings.get(settingsParams.readMoreText)
  const readLessText = settings.get(settingsParams.readLessText)

  if (updatedInterfaceEnabled) {
    return <EventDetailsMobileNew />
  }

  return (
    <section data-hook={DH.eventDetails} className={s.view} aria-label={eventTitle}>
      <div className={classNames(s.background, c.evBackgroundColor)} />
      <div className={classNames({[s.borderWrapper]: sideBySideHeaderLayout})}>
        {isImageVisible() ? <MobileImage /> : null}
        <div className={classNames(s.header)}>
          <div className={classNames(s.headerBackground)} />
          <div className={classNames(s.content)}>
            <div className={getAlignmentClass(headerAlignment)}>
              <h1 className={classNames(s.title, c.evTitleFont, c.evTitleColor)} data-hook={DH.headerEventTitle}>
                {eventTitle}
              </h1>
              {descriptionVisible ? <Description description={eventDescription} /> : null}
              <Subtitle
                dataHook={DH.subtitleTimeAndLocation}
                text={timeAndLocationTitleText}
                customColor={c.evDescriptionColor}
              />
              {moreDatesVisible && <RecurringDatesCount />}
              <div className={classNames(s.paragraph, c.evTextFont, c.evDescriptionColor)}>
                <FullDate />
                <FullLocation />
              </div>
              {membersVisible ? (
                <div className={s.members}>
                  <Members />
                </div>
              ) : null}
            </div>
          </div>
          {moreDatesButtonVisible && <RecurringDatesButton />}
          <RegistrationButton onClick={handleRegistrationButtonClick} />
        </div>
      </div>
      <Container
        visible={aboutSectionVisible}
        contentAlignment={contentAlignment}
        key={1}
        dataHook="aboutSectionContainer"
      >
        <AboutSection aboutTitleText={aboutTitleText} readLessText={readLessText} readMoreText={readMoreText} />
      </Container>
      <Container visible={groupVisible} contentAlignment={contentAlignment} key={2} dataHook="groupsContainer">
        <GroupActivity className={aboutSectionVisible ? s.groupActivityWithAboutSpacing : s.groupActivitySpacing} />
      </Container>
      <Container visible={scheduleVisible} contentAlignment={contentAlignment} key={3} dataHook="scheduleContainer">
        <Schedule t={t} />
      </Container>
      {hasTickets ? (
        <Container visible={true} contentAlignment={contentAlignment} key={4} dataHook="ticketPickerContainer">
          <MobileTicketsPicker />
        </Container>
      ) : null}
      <SecondaryRsvpButton onClick={() => handleRSVP()} />
      {mapVisible && (
        <div className={s.mapSpacing}>
          <Map />
        </div>
      )}
      <Container
        visible={socialShareVisible}
        contentAlignment={contentAlignment}
        key={5}
        dataHook="socialShareContainer"
      >
        <SocialShareWithTitle page={BI_ORIGINS.EVENT_DETAILS} t={t} />
      </Container>
    </section>
  )
}

const Container = ({visible, contentAlignment, children, dataHook}: ContainerProps) =>
  visible ? (
    <div className={`${s.content} ${getAlignmentClass(contentAlignment)}`} data-hook={dataHook}>
      {children}
    </div>
  ) : (
    <div />
  )
